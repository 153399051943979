import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import Collection from '../../../components/collection'

const metaTags = {
  description:
    'Browse a list of office and caregiver resources for SUPPRELIN® LA (histrelin acetate) subcutaneous implant.',
  keywords: 'Homepage',
  title: 'Office Support | SUPPRELIN® LA (histrelin acetate)',
}

const hcpResources = [
  {
    thumb: 'SP-05175_Surgeon_MVA_thumbnail.png',
    title: 'Surgical Procedure Overview',
    copy: `Download full instructions for implanting and removing the <span class='block_nowrap'>SUPPRELIN<sup>&reg;</sup> LA</span> implant`,
    href: '/pdfs/SP-05716_Surgeon_MVA_DIGITAL_final.pdf',
    pdf: true,
    external: false,
    alt: 'Instructions Implanting Removing SUPPRELIN LA',
  },
  {
    thumb: 'MM-05382_SRE_form_thumbnail.png',
    title: 'Service Request Enrollment (SRE) Form',
    copy: 'Investigate patient’s coverage to begin the reimbursement process',
    href: '/pdfs/MM-05735_SLA_SRE_Form_Digital.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Enrollment Form',
  },
  {
    thumb: 'MM-05413_SRE_instructions_thumbnail.png',
    title: 'SRE Instruction Guide',
    copy: 'Best practices to help complete the SRE Form',
    href: '/pdfs/MM-05742_SLA_Service_Request_Enrollment_Instruction_Guide_DIGITAL.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Enrollment Form Instructions',
  },
  {
    thumb: 'MM-05411_BI_instructions_SP_thumbnail.png',
    title: 'Benefits Investigation (BI) Results Form Instruction Guide',
    copy: 'How to read the BI Results Form for Specialty Pharmacy',
    href: '/pdfs/MM-05739_SLA_BI_Results_Form_Instruction_Guide_-_SP_Digital.pdf',
    pdf: true,
    external: false,
    alt: 'Specialty Pharmacy Results Instruction Guide',
  },
  {
    thumb: 'MM-05412_BI_instructions_Hosp_thumbnail.png',
    title: 'Benefits Investigation (BI) Results Form Instruction Guide',
    copy: 'How to read the BI Results Form for Hospital',
    href: '/pdfs/MM-05740_SLA_BI_Results_Form_Instruction_Guide_-_Hospital_DIGITAL.pdf',
    pdf: true,
    external: false,
    alt: 'Hospital Results Instruction Guide',
  },
  {
    thumb: 'MM-05305_LOMN_thumbnail.png',
    title: 'Letter of Medical Necessity',
    copy: 'Claims support for reimbursement of SUPPRELIN<sup>&reg;</sup> LA',
    href: '/pdfs/MM-05737_Letter_of_Medical_Necessity_Supprelin_LA-Digital.pdf',
    pdf: true,
    external: false,
    alt: 'Letter Medical Necessity',
  },
  {
    thumb: 'MM-05303_Appeal_Letter_thumbnail.png',
    title: 'Appeal Tool',
    copy: 'SUPPRELIN<sup>&reg;</sup> LA Implant Claim Appeal Letter',
    href: '/pdfs/MM-05736_SLA_Denied_Claim_Appeal_Letter_digital.pdf',
    pdf: false,
    external: false,
    alt: 'SUPPRELIN LA Appeal Tool',
  },
]

const resources = [
  {
    thumb: 'SP-05378_caregiverbro_thumbnail.png',
    title: 'Caregiver Brochure',
    copy:
      'Important information on CPP and how SUPPRELIN<sup>&reg;</sup> LA can help',
    href: '/pdfs/SP-05689_Supprelin_LA_Caregiver_Brochure_Digital.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Caregiver Brochure',
  },
  {
    thumb: 'SP-05379_gradybear_thumbnail.png',
    title: 'Grady Bear',
    copy: 'Storybook that makes CPP easy for kids to understand',
    href: '/pdfs/SP-05715_SLA_Grady_Bear_Storybook_-_DIGITAL_final.pdf',
    pdf: true,
    external: false,
    alt: 'Grady Bear Book',
  },
  {
    thumb: 'SP-05697_appreminder_thumbnail.png',
    title: 'Appointment Reminder Card',
    copy: `Helps parents and caregivers keep their child's scheduled appointments`,
    href: '/pdfs/SP-05697_Appointment_Reminder_Card_R1_Digital.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Appointment Reminder Card',
  },
  {
    thumb: 'SP-05379_shares_thumbnail.png',
    title: 'SHARES Program',
    copy: 'Information on copay assistance',
    href: '/pdfs/SP-05685_SHARES_Leave_Behind_R1_Digital.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Shares Program',
  },
  {
    thumb: 'SP-05374_Caregiver_Spanish.png',
    title: 'Spanish: Caregiver Brochure',
    copy:
      'Important information on CPP and how SUPPRELIN<sup>&reg;</sup> LA can help',
    href: '/pdfs/SP-05690_Supprelin_LA_Caregiver_Brochure-Spanish-digital.pdf',
    pdf: true,
    alt: 'SUPPRELIN LA Spanish Caregiver Brochure',
  },
  {
    thumb: 'SP-05379_hgfound_thumbnail.png',
    title: 'HGFound.org',
    copy:
      'The Human Growth Foundation leads the way in providing research, education, support, and advocacy in areas of growth or growth-hormone disorders',
    href: 'https://www.hgfound.org/',
    pdf: false,
    external: true,
    alt: 'hgfound.org',
  },
  {
    thumb: 'SP-05379_magicfound_thumbnail.png',
    title: 'MagicFoundation.org',
    copy:
      'The MAGIC Foundation<sup>&reg;</sup> is the world’s largest organization for children and adults with growth-related disorders',
    href: 'https://www.magicfoundation.org/',
    pdf: false,
    external: true,
    alt: 'magicfoundation.org',
  },
]

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>Important forms and resources for your practice</h1>
      </Col>
    </Row>
    <Collection collection={hcpResources} />
    <Row>
      <Col xs={12}>
        <h1>Caregiver resources</h1>
      </Col>
    </Row>
    <Collection
      collection={resources}
      pageName="hcp downloads office support"
    />
    <Row>
      <Col xs={12}>
        <p className="study-copy">All trademarks are the property of their respective owners.</p>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
